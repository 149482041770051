import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Baskerville, G.L. and Emin, P. 1969. Rapid Estimation of Heat
        Accumulation from Maximum and Minimum Temperatures. Ecology. 50:
        514-517.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Reissig, W.H., Barnard, J., Weires, R.W., Glass, E.H., and Dean, R.W.
        1979. Prediction of apple maggot fly emergence from thermal unit
        accumulation. Environ. Entomol. 8: 51-54.
      </p>
    </>
  )
}
