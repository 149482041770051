import parse from "html-react-parser"
import React from "react"

export default function ManagementGuide({
  managementGuideMsg,
  explanation,
  setIsMoreInfo,
}) {
  if (!managementGuideMsg) return null
  return (
    <div>
      {Object.keys(managementGuideMsg).includes("explanation") && (
        <p className="px-6 py-6 mb-8 text-sm leading-6 bg-white border-b-4 border-gray-200 sm:rounded-lg">
          {explanation}
        </p>
      )}
      <div className="hidden md:flex md:flex-col">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  {Object.keys(managementGuideMsg).includes("pestStatus") && (
                    <th className="px-6 py-3 text-xs leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-secondary-800">
                      Pest Status
                    </th>
                  )}
                  {Object.keys(managementGuideMsg).includes(
                    "pestManagement"
                  ) && (
                    <th className="px-6 py-3 text-xs leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-secondary-800">
                      Pest Management
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr>
                  {Object.keys(managementGuideMsg).includes("pestStatus") && (
                    <td className="w-1/2 px-6 py-4 text-sm leading-6 text-gray-700 border-b border-gray-200">
                      {parse(managementGuideMsg.pestStatus)}
                    </td>
                  )}
                  {Object.keys(managementGuideMsg).includes("pestManagement") &&
                    managementGuideMsg.pestManagement !==
                      "View More Info for management information." && (
                      <td className="w-1/2 px-6 py-4 text-sm leading-6 text-gray-700 border-b border-gray-200">
                        {parse(managementGuideMsg.pestManagement)}
                      </td>
                    )}

                  {Object.keys(managementGuideMsg).includes("pestManagement") &&
                    managementGuideMsg.pestManagement ===
                      "View More Info for management information." && (
                      <td className="w-1/2 px-6 py-4 text-sm leading-6 text-gray-700 border-b border-gray-200">
                        View{" "}
                        <button
                          onClick={() => setIsMoreInfo(true)}
                          className="text-blue-500 underline"
                        >
                          More Info
                        </button>{" "}
                        for management information
                      </td>
                    )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Mobile View ---------------------------------------------------*/}
      {/* Pest Status */}
      {Object.keys(managementGuideMsg).includes("pestStatus") && (
        <div className="flex flex-col md:hidden">
          <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-secondary-800">
                      Pest Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  <tr>
                    <td className="w-1/2 px-6 py-4 text-sm leading-6 text-gray-700 border-b border-gray-200">
                      {parse(managementGuideMsg.pestStatus)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Pest Management */}
      {Object.keys(managementGuideMsg).includes("pestManagement") && (
        <div className="flex flex-col mt-8 md:hidden">
          <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-secondary-800">
                      Pest Management
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {managementGuideMsg.pestManagement ===
                  "View More Info for management information." ? (
                    <tr>
                      <td className="w-1/2 px-6 py-4 text-sm leading-6 text-gray-700 border-b border-gray-200">
                        View{" "}
                        <button
                          onClick={() => setIsMoreInfo(true)}
                          className="text-blue-500 underline"
                        >
                          More Info
                        </button>{" "}
                        for management information
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td className="w-1/2 px-6 py-4 text-sm leading-6 text-gray-700 border-b border-gray-200">
                        {parse(managementGuideMsg.pestManagement)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
