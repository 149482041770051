import React from "react"
import Select from "react-select"

const customStylesDropdown = {
  option: (provided) => ({
    ...provided,
    textAlign: "left",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 10,
  }),
  input: (provided) => ({
    ...provided,
    width: "100%",
    textAlign: "left",
  }),
}

export default function Dropdown({
  width,
  id,
  list,
  ariaLabel,
  placeholder,
  setUserItem,
  item,
}) {
  // console.log(list)
  // console.log(item)
  function handleChange(v) {
    setUserItem(list.find((d) => d.value === v.value))
  }
  return (
    <div className={width}>
      <Select
        name={id}
        id={id}
        aria-label={ariaLabel}
        value={item ? list.find((d) => d.value === item.value) : null}
        placeholder={placeholder}
        onChange={(v) => handleChange(v)}
        options={list}
        styles={customStylesDropdown}
        theme={(theme) => ({
          ...theme,
          borderRadius: 10,
          colors: {
            ...theme.colors,
            primary25: "#edf2f7",
            primary50: "#86BFDF",
            primary: "#718096",
            // neutral20: "#718096",
          },
        })}
      ></Select>
    </div>
  )
}
