import GlobalStateContext from "../../context/globalStateContext"
import domtoimage from "dom-to-image"
import { saveAs } from "file-saver"
import React from "react"
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ReferenceLine,
  ReferenceDot,
} from "recharts"

const CustomXLabel = (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-15)"
      >
        {payload.value}
      </text>
    </g>
  )
}

const CustomYLabel = (props) => {
  const { x, y, payload, unit } = props
  return (
    <g>
      <text
        x={x}
        y={y}
        dy={5}
        dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
      >
        {payload.value}
        {unit}
      </text>
    </g>
  )
}

const CustomizedReferenceLineLabel = (props) => {
  const { date, viewBox } = props
  return (
    <g>
      <text
        x={viewBox.x}
        y={viewBox.y}
        dy={-5}
        // dx={-15}
        fontSize={11}
        textAnchor="middle"
        // fill="#666"
      >
        {date}
      </text>
    </g>
  )
}

const rendererLegend = ({ payload }) => {
  return payload.map((entry, i) => {
    return (
      <div
        key={i}
        className="flex justify-center text-xs font-semibold sm:text-sm md:text-base"
        style={{ color: entry.color }}
      >
        {entry.value}
      </div>
    )
  })
}

const renderTooltip = ({ payload }) => {
  if (typeof payload === "undefined") return null
  return payload.map((entry, i) => {
    if (i === 0) {
      return (
        <div
          key={i}
          className="flex flex-col px-4 py-3 rounded bg-gray-50 opacity-90"
        >
          <div>
            <span className="mr-1 text-xs font-semibold">Date:</span>
            <span className="text-xs">{entry.payload.dateDisplay}</span>
          </div>
          <div className="mt-2">
            <span className="mr-1 text-xs font-semibold">DD from Jan 1:</span>
            <span className="text-xs">{entry.payload.base50BEGdd}</span>
          </div>
          {Object.keys(entry.payload).includes("biofix1Gdd") &&
            entry.payload.biofix1Gdd !== "N/A" && (
              <div>
                <span className="mr-1 text-xs font-semibold">
                  DD from First Trap Catch:
                </span>
                <span className="text-xs">{entry.payload.biofix1Gdd}</span>
              </div>
            )}
        </div>
      )
    } else {
      return null
    }
  })
}

export default function ResultsGraph({ modelData, data, biofix1 }) {
  const { baseFormula, baseFormulaDisplay } = modelData
  const { resultsGraph } = modelData.elements
  const { dateOfInterest } = React.useContext(GlobalStateContext)

  function downloadPNG() {
    domtoimage
      .toBlob(document.getElementById("chart"))
      .then((blob) => saveAs(blob, `${modelData.title} Chart.png`))
  }

  if (data === null) return null
  return (
    <div className="w-full" aria-hidden="true">
      <div className="flex items-center justify-between mb-6">
        <h2 className="inline-block text-lg font-semibold text-gray-600 sm:text-xl md:text-2xl">
          {resultsGraph.title}
        </h2>

        <div className="flex items-center justify-center rounded-md">
          <button
            type="button"
            aria-label="download graph in png format"
            onClick={downloadPNG}
            className="inline-flex items-center p-2 text-sm font-bold leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>
            <span className="hidden mr-1 sm:inline-block">Download </span>{" "}
            <span className="text-white">PNG</span>
          </button>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={450} id="chart">
        <ComposedChart
          data={data}
          margin={{ top: 20, right: 30, left: -30, bottom: 20 }}
          className="bg-white border-b border-gray-200 shadow sm:rounded-lg"
        >
          <XAxis
            dataKey="dateDisplay"
            interval={"preserveStartEnd"}
            axisLine={true}
            tick={<CustomXLabel />}
          ></XAxis>
          <YAxis
            width={110}
            dataKey={`${baseFormula}Gdd`}
            tick={<CustomYLabel />}
            label={{
              value: "Accumulated Degree-Days",
              angle: -90,
              position: "center",
              offset: 0,
            }}
          />
          <Tooltip content={renderTooltip} />
          <Legend verticalAlign="top" height={120} content={rendererLegend} />
          <Line
            type="monotone"
            dataKey={`${baseFormula}Gdd`}
            stroke="#2563EB"
            strokeWidth={2}
            dot={false}
            name={`${modelData.title} DD ${baseFormulaDisplay} From Jan 1`}
          />
          <ReferenceLine
            x={dateOfInterest.server}
            stroke="#4a5568"
            strokeWidth={1.1}
            label={
              <CustomizedReferenceLineLabel date={dateOfInterest.server} />
            }
          />
          <ReferenceDot
            x={dateOfInterest.server}
            y={data[dateOfInterest.dayOfYear - 1][`${baseFormula}Gdd`]}
            r={4}
            fill="#4a5568"
            stroke="none"
          />

          {biofix1 !== null && (
            <Line
              type="monotone"
              dataKey="biofix1Gdd"
              stroke="#059669"
              strokeWidth={2}
              dot={false}
              name={`${modelData.title} DD ${baseFormulaDisplay} From First Trap Catch`}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
